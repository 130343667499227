




























import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { Apiary, User } from '@/types';

@Component
export default class ApiarySuperActions extends Vue {
  @Prop({ type: Object, default: null }) readonly apiary!: Apiary | null

  loading = false
  updating = false

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get isSuper(): boolean {
    return this.currentUser.isSuper;
  }

  get currentUserId(): string | null {
    return this.currentUser.uid ?? null;
  }

  // Ritorna lo UID dell'apicoltore che gestisce l'apiario selezionato
  get apiaryBeekeeperUID(): string | null {
    return this.apiary?.beekeeper.uid ?? null;
  }

  // Ritorna true se l'utente attuale è l'apicoltore che gestisce l'apiario selezionato
  get isBeekeeper(): boolean {
    return this.currentUser.isBeekeeper
      && this.currentUserId !== null
      && this.apiaryBeekeeperUID !== null
      && this.currentUserId === this.apiaryBeekeeperUID;
  }

  get isVisible(): boolean {
    return this.isLoggedIn && (this.isSuper || this.isBeekeeper);
  }

  onNewUpdate() {
    console.log(`${this.constructor.name}: onNewUpdate()`); // eslint-disable-line no-console

    // Preseleziona l'apiario attualmente mostrato
    this.$store.dispatch('setUpdateInitialApiary', this.apiary?.id);

    // Mostra il dialog per la pubblicazione degli aggiornamenti
    this.$store.dispatch('openDialog', 'newUpdate');
  }
}
