

























































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import * as L from 'leaflet';
import {
  LMap, LTileLayer, LControlZoom,
  LImageOverlay,
} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import ApiariesCarousel from '@/components/ApiariesCarousel.vue';
import MapBar from '@/components/MapBar.vue';
import MapLogo from '@/components/MapLogo.vue';
import { Apiary, User } from '@/types';

@Component({
  components: {
    MapBar,
    ApiariesCarousel,
    MapLogo,
    LMap,
    LTileLayer,
    LControlZoom,
    LImageOverlay,
  },
  metaInfo(this: Map) {
    return {
      title: 'Mielopolis.it - Scegli il tuo apiario',
    };
  },
})
export default class Map extends Vue {
  email = ''
  privacy = false

  crs = L.CRS.Simple

  zoom = 4
  center = [-50.000000, 180.000000]
  url = L.Browser.retina
    ? '/map-images/base-v5@2x/{z}/{x}/{y}.png'
    : '/map-images/base-v5/{z}/{x}/{y}.png'
  attribution = `&copy; ${new Date().getFullYear()} <strong>Società Agricola Frazchina S.S.</strong> Via Matteotti, 64 Argenta (FE) - P.IVA: 01966400382`
  currentZoom = 4
  currentCenter = [-50.000000, 180.000000]
  showParagraph = false
  mapOptions = {
    zoomControl: false,
    zoomSnap: 0.5,
    maxBoundsViscosity: 0.8,
  }

  map = null as L.Map | null

  hideCircles = false

  markers: {
    [id: string]: L.Marker<any>;
  } = {}

  selectedApiary: Apiary | null = null
  canDeselctApiary = false

  async created() {
    console.log('Map: created() this.$route.params.uid = ', this.$route.params.uid); // eslint-disable-line no-console

    this.$store.dispatch('mapNotReady');
  }

  destroyed() {
    console.log('Map: destroyed()'); // eslint-disable-line no-console
  }

  mounted() {
    setTimeout(() => {
      console.log('Map: mounted() resize'); // eslint-disable-line no-console
      window.dispatchEvent(new Event('resize'));

      setTimeout(() => {
        if (Object.keys(this.markers).length === 0) {
          console.log('Map: mounted() initCircles'); // eslint-disable-line no-console

          this.initCircles();
        }
      }, 1000);
    }, 500);
  }

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries
      .filter((a: Apiary) => a.enabled !== false);
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get isRetina() {
    return L.Browser.retina;
  }

  get zoomClass() {
    const zoom = `${this.currentZoom}`.replace('.', '-');
    return `zoom-${zoom}`;
  }

  // bounds() {
  //   const size = 1.5;
  //   return [[-49.000000 - size, 185.000000 - size], [-49.000000 + size, 185.000000 + size]];
  // },

  @Watch('apiaries')
  async onApiariesChange(val: Apiary[], oldVal: Apiary[]) {
    console.log('Map: onApiariesChange()'); // eslint-disable-line no-console

    if (val.length > 0) {
      setTimeout(() => {
        console.log('Map: onApiariesChange() chiamo initCircles()'); // eslint-disable-line no-console

        this.initCircles();
      }, 1000);
    }
  }

  zoomUpdate(zoom: any) {
    console.log('Map: zoomUpdate()'); // eslint-disable-line no-console
    this.hideCircles = true;
    this.currentZoom = zoom;
    setTimeout(() => {
      this.hideCircles = false;
    }, 1000);
  }

  centerUpdate(center: any) {
    this.currentCenter = center;
  }

  showLongText() {
    this.showParagraph = !this.showParagraph;
  }

  onMapReady() {
    console.log('Map: onMapReady()'); // eslint-disable-line no-console

    const mapRef: any = this.$refs.myMap;

    if ('mapObject' in mapRef === false) return;
    if (!(mapRef.mapObject instanceof L.Map)) return;

    this.map = mapRef.mapObject;

    if (!this.map) return;

    // eslint-disable-next-line no-underscore-dangle
    const originalInitTile = (L.GridLayer.prototype as any)._initTile;
    L.GridLayer.include({
      // eslint-disable-next-line no-underscore-dangle
      _initTile(tile: any) {
        originalInitTile.call(this, tile);

        const tileSize = this.getTileSize();

        // eslint-disable-next-line no-param-reassign, prefer-template
        tile.style.width = tileSize.x + 1 + 'px';
        // eslint-disable-next-line no-param-reassign, prefer-template
        tile.style.height = tileSize.y + 1 + 'px';
      },
    });

    const southWest = this.map.unproject([0, 4352], this.map.getMaxZoom());
    const northEast = this.map.unproject([8192, 0], this.map.getMaxZoom());

    // eslint-disable-next-line new-cap
    this.map.setMaxBounds(L.latLngBounds(southWest, northEast));
  }

  apiaryURL(apiary: Apiary): string {
    return `${process.env.BASE_URL}map-images/${apiary.image}`;
  }

  apiaryBounds(apiary: Apiary) {
    const size = 1;
    return [[apiary.posX - size, apiary.posY - size], [apiary.posX + size, apiary.posY + size]];
  }

  isSelected(apiary: Apiary) {
    return this.selectedApiary?.id === apiary.id;
  }

  initCircles() {
    console.log('Map: initCircles()'); // eslint-disable-line no-console

    if (this.apiaries.length === 0) return;

    console.log('Map: initCircles() this.markers =', this.markers); // eslint-disable-line no-console
    if (Object.keys(this.markers).length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const id in this.markers) {
        if (Object.prototype.hasOwnProperty.call(this.markers, id)) {
          const marker = this.markers[id];
          marker.remove();
        }
      }
    }

    const iconSize = L.point(60, 60);

    console.log('Map: initCircles() this.apiaries =', this.apiaries); // eslint-disable-line no-console
    this.apiaries.forEach((apiary) => {
      if (!this.map) {
        console.log('Map: initCircles() La mappa non è pronta!'); // eslint-disable-line no-console

        return;
      }

      const element = this.$refs[`apiary-circle-${apiary.id}`] as HTMLElement[];

      const myIcon = L.divIcon({
        className: 'my-div-icon',
        html: element[0],
        iconSize,
      });

      const myIconPosition = L.latLng(apiary.posX, apiary.posY);

      const marker = L.marker(myIconPosition, {
        icon: myIcon,
        // bubblingMouseEvents: false,
        // riseOnHover: true,
      }).addTo(this.map);

      if ('id' in apiary && apiary.id) this.markers[apiary.id] = marker;
    });

    this.$store.dispatch('mapReady');
  }

  unselectApiary() {
    console.log('Map: unselectApiary()'); // eslint-disable-line no-console

    if (this.selectedApiary) {
      this.selectedApiary = null;
    }

    if (this.map) this.map.removeEventListener('click', this.unselectApiary);
  }

  selectApiary(id: string) {
    console.log('Map: selectApiary() id =', id); // eslint-disable-line no-console

    // Seleziona l'apiario
    this.selectedApiary = this.apiaries.find((a) => a.id === id) ?? null;

    // Se è stato effettivamente selezionato un apiario...
    if (this.selectedApiary) {
      // ... lo porta in primo piano

      // eslint-disable-next-line no-restricted-syntax
      for (const markerId in this.markers) {
        if (Object.prototype.hasOwnProperty.call(this.markers, markerId)) {
          const marker = this.markers[markerId];
          marker.setZIndexOffset(id === this.selectedApiary.id ? 1000 : 100);
        }
      }

      // Sposta la visuale sull'apiario
      setTimeout(() => {
        if (this.map && this.selectedApiary) {
          const offset = (5 / this.currentZoom) + (5 / this.currentZoom) * 4;
          this.map.setView(
            L.latLng(this.selectedApiary.posX + offset, this.selectedApiary.posY),
            this.currentZoom < 4 ? 4 : this.currentZoom,
          );
        }
      }, 200);

      setTimeout(() => {
        if (this.map) {
          this.map.addEventListener('click', this.unselectApiary);
        }
      }, 300);
    }
  }

  onApiaryClick(apiary: Apiary) {
    console.log('Map: onApiaryClick()'); // eslint-disable-line no-console

    if (this.map) this.map.removeEventListener('click', this.unselectApiary);

    if (apiary.id) this.selectApiary(apiary.id);
  }

  onApiaryEnter(apiary: Apiary) {
    console.log('Map: onApiaryEnter()'); // eslint-disable-line no-console

    if (!this.map) return;

    // eslint-disable-next-line no-restricted-syntax
    for (const id in this.markers) {
      if (Object.prototype.hasOwnProperty.call(this.markers, id)) {
        const marker = this.markers[id];
        marker.setZIndexOffset(id === apiary.id ? 1000 : 100);
      }
    }
  }

  onApiariesCarouselSelect(id: string | null) {
    console.log('Map: onApiariesCarouselSelect() id =', id); // eslint-disable-line no-console

    if (id === null) {
      // Deseleziona l'apiario selezionato
      this.selectedApiary = null;
    } else

    if (this.selectedApiary?.id && this.selectedApiary.id !== id) {
      // Seleziona il nuovo apiario
      this.selectApiary(id);
    }
  }
}
