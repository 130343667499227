












































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import { Apiary } from '@/types';
import Progress from '@/components/Progress.vue';
import ApiaryDialog from '@/components/ApiaryDialog.vue';
import Flickity from 'flickity';
import ImageWithLabel from '@/components/ImageWithLabel.vue';
import ApiarySuperActions from '@/components/ApiarySuperActions.vue';

@Component({
  components: {
    Progress,
    ApiaryDialog,
    ImageWithLabel,
    ApiarySuperActions,
  },
})
export default class ApiariesCarousel extends Vue {
  @Prop({ type: Object, default: null }) readonly apiary!: Apiary

  carousel: Flickity | null = null

  get apiaries(): Apiary[] {
    return this.$store.getters.apiaries
      .filter((a: Apiary) => a.enabled !== false);
  }

  get hidden(): boolean {
    return this.apiary === null;
  }

  get isMapReady(): boolean {
    return this.$store.getters.isMapReady;
  }

  @Watch('isMapReady')
  onIsMapReadyChange(val: boolean, oldVal: boolean) {
    console.log('ApiariesCarousel: onIsMapReadyChange()'); // eslint-disable-line no-console

    if (val === true && oldVal === false) {
      this.carousel = new Flickity('.main-carousel', {
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
      });

      this.carousel.on('change', this.onSlideChange);
    }
  }

  @Watch('apiaries')
  async onApiariesChange(val: Apiary[], oldVal: Apiary[]) {
    console.log('ApiariesCarousel: onApiariesChange()'); // eslint-disable-line no-console
  }

  @Watch('apiary')
  async onApiaryChange(val: Apiary | null, oldVal: Apiary | null) {
    console.log('ApiariesCarousel: onApiaryChange()'); // eslint-disable-line no-console

    if (!this.carousel) return;

    const index = this.apiaries.findIndex((a) => a.id === val?.id);

    console.log('ApiariesCarousel: onApiaryChange() index =', index); // eslint-disable-line no-console

    this.carousel.select(index);
  }

  headerImage(apiary: Apiary): string {
    return apiary?.headerImage ? `/apiaries/${apiary.headerImage}` : '/apiaries/fake-header-01.jpg';
  }

  isFull(apiary: Apiary): boolean {
    return apiary.isFull === true;
  }

  percent(apiary: Apiary): number {
    return (apiary.hivesFull / apiary.hivesCount) * 100;
  }

  hivesCount(apiary: Apiary): number {
    return apiary.hivesCount;
  }

  hivesFull(apiary: Apiary): number {
    return apiary.hivesFull;
  }

  hivesLeft(apiary: Apiary): number {
    return apiary.hivesCount - apiary.hivesFull;
  }

  progressText(apiary: Apiary): string {
    return apiary.isFull
      ? 'Nessun favo disponibile'
      : `${this.hivesLeft(apiary)} arnie su ${this.hivesCount(apiary)} con favi disponibili`;
  }

  progressColor(apiary: Apiary): string {
    return apiary.isFull
      ? '#D15555'
      : '#F4BC46';
  }

  onSlideChange(index: number | undefined) {
    if (index !== undefined && index in this.apiaries) {
      this.$emit('select', this.apiaries[index].id);
    }
  }

  onShow() {
    console.log(`${this.constructor.name}: onShow()`); // eslint-disable-line no-console

    this.$store.dispatch('openDialog', 'apiary');
  }

  onSelect() {
    console.log(`${this.constructor.name}: onSelect() id =`, this.apiary?.id); // eslint-disable-line no-console

    if (!this.apiary?.id) return;

    this.$router.push({
      name: 'Apiary',
      params: {
        id: this.apiary.id,
      },
    });
  }
}
